@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply min-h-full m-0 bg-primary-500;
  }

  body {
    @apply min-h-full m-0;
  }

  #root {
    @apply min-h-full m-0;
  }
}

@layer components {

  .sidebar-icon {
    @apply cursor-pointer;
  }

  .sidebar-icon>.icon {
    @apply relative flex items-center justify-center
    h-12 w-12 mt-2 mb-1 mx-auto
    shadow-lg bg-primary text-accent rounded-full hover:bg-primary-200;
  }

  .sidebar-icon>.text {
    @apply w-12 mb-2 mx-auto
  text-accent text-center hover:text-accent-700;
  }

  .sidebar-icon.small>.icon {
    @apply h-7 w-7;
  }

  .sidebar-icon.small>.text {
    font-size: 70%;
  }

  .icon.selected {
    @apply bg-accent hover:bg-accent-700;
  }

  .text.selected {
    @apply text-accent hover:text-accent-700;
  }

  .map-tooltip {
    max-width: 300px;
    white-space: normal;
  }

  .invisible-file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .invisible-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .invisible-file-input::before {
    content: 'Upload files';
    display: inline-block;
    outline: none;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .spot-file-uploader svg {
    display: none;
  }

  #general-toast-container {
    position: relative;
    z-index: 999999999;
  }
}