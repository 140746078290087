/* Style for h1 element */
#privacy-policy-container h1 {
  font-size: 34px;
  font-weight: bold;
  color: #21323D; /* Change the color to your preference */
  margin-top: 10px;
  margin-bottom: 22px;
}

/* Style for h2 elements */
#privacy-policy-container h2 {
  font-size: 20px;
  font-weight: bold;
  color: #21323D; /* Change the color to your preference */
  margin-top: 20px;
  margin-bottom: 10px;
}

#privacy-policy-container h3 {
  font-size: 16px;
  font-weight: bold;
  color: #21323D; /* Change the color to your preference */
  margin-top: 20px;
  margin-bottom: 10px;
}

/* Style for p elements */
#privacy-policy-container p {
  font-size: 16px;
  line-height: 1.5;
  color: #000; /* Change the color to your preference */
  margin-bottom: 10px;
  margin-top: 5px;
}

/* Style for ul elements */
#privacy-policy-container ul {
  list-style-type: disc; /* Change the list style type as needed (e.g., disc, square, circle) */
  margin-left: 20px;
  color: #000;
}

/* Style for li elements inside ul */
#privacy-policy-container ul li {
  font-size: 16px;
  color: #000; /* Change the color to your preference */
  margin-bottom: 5px;
}

#privacy-policy-container table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  color: #000
}

#privacy-policy-container th, 
#privacy-policy-container td {
  border: 1px solid #D0D0D0;
  text-align: left;
  padding: 8px;
}
