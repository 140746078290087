/* FONTS */
@font-face {
  font-family: 'Messina Sans';
  src: url('../assets/font/MessinaSans-Bold.woff2') format('woff2'),
       url('../assets/font/MessinaSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Messina Sans';
  src: url('../assets/font/MessinaSans-Regular.woff2') format('woff2'),
       url('../assets/font/MessinaSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Messina Sans';
  src: url('../assets/font/MessinaSans-Light.woff2') format('woff2'),
       url('../assets/font/MessinaSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Neusa Next Pro';
  src: url('../assets/font/NeusaNextPro-Bold.woff2') format('woff2'),
       url('../assets/font/NeusaNextPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Neusa Next Pro';
  src: url('../assets/font/NeusaNextPro-Regular.woff2') format('woff2'),
       url('../assets/font/NeusaNextPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Neusa Next Pro';
  src: url('../assets/font/NeusaNextPro-Light.woff2') format('woff2'),
       url('../assets/font/NeusaNextPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
